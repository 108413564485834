// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-template-js": () => import("/opt/build/repo/src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-docs-index-js": () => import("/opt/build/repo/src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-javascript-integration-js": () => import("/opt/build/repo/src/pages/docs/javascript-integration.js" /* webpackChunkName: "component---src-pages-docs-javascript-integration-js" */),
  "component---src-pages-docs-reactjs-integration-js": () => import("/opt/build/repo/src/pages/docs/reactjs-integration.js" /* webpackChunkName: "component---src-pages-docs-reactjs-integration-js" */),
  "component---src-pages-features-image-optimization-js": () => import("/opt/build/repo/src/pages/features/image-optimization.js" /* webpackChunkName: "component---src-pages-features-image-optimization-js" */),
  "component---src-pages-features-index-js": () => import("/opt/build/repo/src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-features-lazy-loading-images-js": () => import("/opt/build/repo/src/pages/features/lazy-loading-images.js" /* webpackChunkName: "component---src-pages-features-lazy-loading-images-js" */),
  "component---src-pages-features-progressive-loading-images-js": () => import("/opt/build/repo/src/pages/features/progressive-loading-images.js" /* webpackChunkName: "component---src-pages-features-progressive-loading-images-js" */),
  "component---src-pages-features-remote-image-sources-js": () => import("/opt/build/repo/src/pages/features/remote-image-sources.js" /* webpackChunkName: "component---src-pages-features-remote-image-sources-js" */),
  "component---src-pages-features-responsive-images-js": () => import("/opt/build/repo/src/pages/features/responsive-images.js" /* webpackChunkName: "component---src-pages-features-responsive-images-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-index-js": () => import("/opt/build/repo/src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-index-js": () => import("/opt/build/repo/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-terms-index-js": () => import("/opt/build/repo/src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

